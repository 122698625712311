<template lang="html">
    <div class="card card-primary card-outline">
        <div class="card-header">
            <h3 class="card-title">修改头像</h3>
            <div class="card-tools mr-0">
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-0 p-sm-4">
            <div>
                <div style="max-width:500px">
                    <!-- Profile Image -->
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
                                <div>
                                    <i class="fas fa-spinner fa-pulse"></i>
                                    <p>
                                        载入中…
                                    </p>
                                </div>
                            </div>
                            <div class="text-center">
                                <img class="profile-user-img img-fluid img-circle" :src="user.avatarUrl" alt="User profile picture">
                            </div>

                            <h3 class="profile-username text-center">{{user.nickName}}</h3>

                            <p class="text-muted text-center">{{user.userName}}</p>
                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item" style="display:flex;justify-content:center;">
                                    <div style="width:300px">
                                        <div style="display:flex;">
                                            <label class="btn btn-primary mr-1" style="flex:auto" :disabled="updateing">
                                                选择头像
                                                <input type="file" @change="uploadImg($event, 1)" style="position: absolute;clip: rect(0 0 0 0);">
                                            </label>
                                            <label class="btn btn-primary" style="flex:auto" @click="pullWeixin()" :disabled="updateing">
                                                同步微信头像
                                            </label>
                                        </div>
                                        <div class="text-center">
                                            <div class="top_container">
                                                <div class="dummy"></div>
                                                <div class="cropper">
                                                    <vueCropper ref="cropper"
                                                                :img="option.img"
                                                                :outputSize="option.size"
                                                                :outputType="option.outputType"
                                                                :info="false"
                                                                :full="option.full"
                                                                :fixed="option.fixed"
                                                                :canMove="option.canMove"
                                                                :canMoveBox="option.canMoveBox"
                                                                :fixedBox="option.fixedBox"
                                                                :original="option.original"
                                                                :autoCrop="option.autoCrop"
                                                                :autoCropWidth="option.autoCropWidth"
                                                                :autoCropHeight="option.autoCropHeight"
                                                                :centerBox="option.centerBox"
                                                                :high="option.high"
                                                                :infoTrue="option.infoTrue"
                                                                :mode="option.mode"
                                                                :maxImgSize="option.maxImgSize"
                                                                @realTime="realTime"
                                                                @imgLoad="imgLoad"
                                                                @cropMoving="cropMoving"
                                                                :enlarge="option.enlarge">
                                                    </vueCropper>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display:flex;justify-content:space-between;flex-wrap:wrap;">
                                            <button class="btn btn-default mt-1" @click="refreshCrop" title="重置"><i class="fas fa-sync"></i></button>
                                            <button class="btn btn-default mt-1" @click="changeScale(-1)" title="缩小"><i class="fas fa-search-minus"></i></button>
                                            <button class="btn btn-default mt-1" @click="changeScale(1)" title="放大"><i class="fas fa-search-plus"></i></button>
                                            <button class="btn btn-default mt-1" @click="rotateLeft" title="左旋转"><i class="fas fa-undo"></i></button>
                                            <button class="btn btn-default mt-1" @click="rotateRight" title="右旋转"><i class="fas fa-redo"></i></button>
                                            <button class="btn btn-primary mt-1" @click="finish('blob')" :disabled="updateing">确定</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <!-- end loading -->

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { VueCropper } from 'vue-cropper'

    export default {
        components: {
            VueCropper
        },
        data() {
            return {
                user: {},
                loading: false,
                updateing: false,
                model: false,
                modelSrc: "",
                crap: false,
                previews: {},
                option: {
                    img: "",
                    size: 0.9,
                    full: false,
                    fixed: true,
                    outputType: "jpeg",
                    canMove: true,
                    fixedBox: true,
                    original: false,
                    canMoveBox: false,
                    autoCrop: true,
                    // 只有自动截图开启 宽度高度才生效
                    autoCropWidth: 200,
                    autoCropHeight: 200,
                    centerBox: true,
                    high: false,
                    infoTrue: true,
                    cropData: {},
                    enlarge: 3,
                    mode: 'cover',
                    maxImgSize: 2000
                },
            }
        },
        created() {
            this.loadData()
            //this.option.img = 'https://avatars2.githubusercontent.com/u/15681693?s=460&v=4'
        },
        methods: {
            ...mapActions([
                'getUser'
            ]),
            loadData() {
                var url = "/Api/My/Users"
                this.loading = true
                this.$axios.get(url)
                    .then((response) => {
                        console.log(response)
                        this.user = response.data
                        this.loading = false
                    }).catch((error) => {
                        console.log(error)
                        this.loading = false
                    })

            },
            pullWeixin() {
                this.updateing = true
                var url = "/Api/My/Avatar/PullWeixin"
                this.$axios.get(url)
                    .then((response) => {
                        this.loadData()
                        console.log(response)
                        this.$message({
                            message: '拉取微信头像成功',
                            type: 'info',
                            zIndex: 9999,
                        })
                        this.updateing = false
                    }).catch((error) => {
                        console.log(error)
                        this.$message({
                            message: error.response.data,
                            type: 'info',
                            zIndex: 9999,
                        })
                        this.updateing = false
                    })
            },
            reset() {
                this.item.guid = null
                this.item.id = null
                this.item.name = null
                this.item.closed = false
            },
            save() {
                this.updateing = true
                let data = new FormData();
                data.append('file', this.avatar, "Avatar.jpg");
                var config = {
                    url: "/Api/My/Avatar/Upload",
                    method: 'POST',
                    data: data,
                }
                this.$axios.request(config)
                    .then((response) => {
                        console.log(response)
                        //this.loadData()
                        this.getUser()
                        this.updateing = false
                    })
                    .catch((error) => {
                        console.log(error)
                        this.updateing = false
                    })
            },
            clearCrop() {
                // clear
                this.$refs.cropper.clearCrop();
            },
            refreshCrop() {
                // clear
                this.$refs.cropper.refresh();
            },
            changeScale(num) {
                num = num || 1;
                this.$refs.cropper.changeScale(num);
            },
            rotateLeft() {
                this.$refs.cropper.rotateLeft();
            },
            rotateRight() {
                this.$refs.cropper.rotateRight();
            },
            finish(type) {
                this.updateing = true
                // 输出
                if (type === 'blob') {
                    this.$refs.cropper.getCropBlob((data) => {

                        let formData = new FormData();
                        formData.append('file', data, "Avatar.jpg");
                        var config = {
                            url: "/Api/My/Avatar/Upload",
                            method: 'POST',
                            data: formData,
                        }
                        this.$axios.request(config)
                            .then((response) => {
                                this.loadData()
                                this.option.img = "";
                                //this.refreshCrop();
                                this.$message({
                                    message: '上传头像成功',
                                    type: 'info',
                                    zIndex: 9999,
                                })
                                this.updateing = false
                            })
                            .catch((error) => {
                                console.log(error)
                                this.updateing = false
                            })

                    })
                } else {
                    this.$refs.cropper.getCropData((data) => {
                        //裁切生成的base64图片
                        console.log(data);
                        this.crap = false;
                    })
                }
            },
            realTime(data) {
                this.previews = data
            },
            uploadImg(e, num) {
                //上传图片
                // this.option.img
                var file = e.target.files[0];
                if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                    alert("图片类型必须是.jpg,jpeg,png,gif,bmp中的一种");
                    return false;
                }
                var reader = new FileReader();
                reader.onload = e => {
                    let data;
                    if (typeof e.target.result === "object") {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        data = window.URL.createObjectURL(new Blob([e.target.result]));
                    } else {
                        data = e.target.result;
                    }
                    if (num === 1) {
                        this.option.img = data;
                    } else if (num === 2) {
                        //this.example2.img = data;
                    }
                };
                // 转化为base64
                // reader.readAsDataURL(file)
                // 转化为blob
                reader.readAsArrayBuffer(file);
            },
            imgLoad(msg) {
                console.log(msg);
            },
            cropMoving(data) {
                this.option.cropData = data;
            }
        },
        computed: {
            ...mapGetters([
                'currentUser'
            ]),
        },
    }
</script>

<style lang="css">
    .top_container {
        width: 100%;
        position: relative;
        display: inline-block;
        max-width: 300px;
    }

    .dummy {
        margin-top: 100%;
    }

    .cropper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
</style>
